import { apiService } from 'services/axiosHandler';
import { setCurrentUser, setAccessToken, setRefreshToken, getRefreshToken } from 'helpers/Utils';

export const login = async (username, password) => {
    
    const res = await apiService.post('/auth/login', { username, password }, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (res.status !== 200) {
        throw res.data.message || res.statusText || res
    }

    setAccessToken(res.data.tokens.access.token);
    setRefreshToken(res.data.tokens.refresh.token);
    setCurrentUser({ ...res.data.user, permissions: res.data.permissions });

    return res.data;
};

export const logout = async () => {
    const refreshToken = getRefreshToken()
    
    const res = await apiService.post('/auth/logout', {
        refreshToken
    }, 
    {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (res.status !== 200) {
        throw res.data.message || res.statusText || res
    }

    return res.data;
};
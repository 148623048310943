import { getCurrentUser } from 'helpers/Utils';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
} from './constant';

const INIT_STATE = {
  currentUser: getCurrentUser(),
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload, 
        error: null,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        currentUser: null,
        error: null
      };
    default:
      return { ...state };
  }
};

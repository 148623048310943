import * as typeChildHealthStatus from './constant_childhealthstatus';
import * as typeChildCoordinate from './constant_childcoordinate';
import * as typeRecapPerClinic from './constant_recapperclinic';

export const getReportChildHealthStatus = (category, year) => ({
  type: typeChildHealthStatus.GET,
  category,
  year,
});

export const getReportChildHealthStatusSuccess = (payload, category, year) => ({
  type: typeChildHealthStatus.GET_SUCCESS,
  payload,
  category,
  year,
});

export const getReportChildHealthStatusError = (error, category) => ({
  type: typeChildHealthStatus.GET_ERROR,
  error,
  category,
});

export const getReportChildCoordinate = (category='', value) => ({
  type: typeChildCoordinate.GET,
  category,
  value,
});

export const getReportChildCoordinateSuccess = (payload, category='', value) => ({
  type: typeChildCoordinate.GET_SUCCESS,
  payload,
  category,
  value,
});

export const getReportChildCoordinateError = (error, category='') => ({
  type: typeChildCoordinate.GET_ERROR,
  error,
  category,
});

export const getReportRecapPerClinic = (params = undefined) => ({
  type: typeRecapPerClinic.GET,
  params,
});

export const getReportRecapPerClinicSuccess = (payload, month) => ({
  type: typeRecapPerClinic.GET_SUCCESS,
  payload,
  month,
});

export const getReportRecapPerClinicError = (error) => ({
  type: typeRecapPerClinic.GET_ERROR,
  error,
});

import * as type from './constant';

const initialState = {
  dataProgramPackageCategory: [],

  updatedProgramPackageCategory: null,
  addedProgramPackageCategory: null,
  deletedProgramPackageCategory: null,

  loading: false,
  loadingManipulation: false,
  error: null,
  errorManipulation: null,
};
export default (state = initialState, action) => {

  switch (action.type) {

    case type.GET:
      return {
        ...state,
        updatedProgramPackageCategory: null,
        addedProgramPackageCategory: null,
        deletedProgramPackageCategory: null,
        loading: true,
        loadingManipulation: false,
        error: null,
        errorManipulation: null,
      };

    case type.GET_SUCCESS:
      return {
        ...state,
        dataProgramPackageCategory: [...action.payload],
        loading: false,
      };

    case type.GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case type.ADD:
      return {
        ...state,
        addedProgramPackageCategory: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.ADD_SUCCESS:
      return {
        ...state,
        addedProgramPackageCategory: action.payload,
        loadingManipulation: false,
      };

    case type.UPDATE:
      return {
        ...state,
        updatedProgramPackageCategory: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.UPDATE_SUCCESS:
      return {
        ...state,
        updatedProgramPackageCategory: action.payload,
        loadingManipulation: false,
      };

    case type.DELETE:
      return {
        ...state,
        deletedProgramPackageCategory: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.DELETE_SUCCESS:
      return {
        ...state,
        deletedProgramPackageCategory: action.payload,
        loadingManipulation: false,
      };

    case type.MANIPULATION_ERROR:
      return {
        ...state,
        loadingManipulation: false,
        errorManipulation: action.error,
      };

    default:
      return state;
  }
};

import { apiService } from 'services/axiosHandler';

const path = 'childgrowths';

export const getChildGrowthRequest = async (params = undefined, selfRole = undefined) => {
    let headers;
    if(selfRole){
        headers = {
            'x-self-role': selfRole
        }
    }
    
    const req = await apiService.get(`/${path}`, { 
        params,
        headers
    });

    if (req.status !== 200) {
        throw req.data.message || req.statusText || req
    }

    return req.data;
};

export const addChildGrowthRequest = async (payload, selfRole = undefined) => {
    let headers;
    if(selfRole){
        headers = {
            'x-self-role': selfRole
        }
    }
    
    const req = await apiService.post(`/${path}`, payload, { headers });

    if (req.status !== 201) {
        throw req.data.message || req.statusText || req
    }

    return req.data;
};
  
export const updateChildGrowthRequest = async (id, payload, selfRole = undefined) => {
    let headers;
    if(selfRole){
        headers = {
            'x-self-role': selfRole
        }
    }

    const req = await apiService.patch(`/${path}/${id}`, payload, { headers });

    if (req.status !== 200) {
        throw req.data.message || req.statusText || req
    }

    return req.data;
};
  
export const deleteChildGrowthRequest = async (id, selfRole = undefined) => {
    let headers;
    if(selfRole){
        headers = {
            'x-self-role': selfRole
        }
    }

    const req = await apiService.delete(`/${path}/${id}`, { headers });

    if (req.status !== 204) {
        throw req.data.message || req.statusText || req
    }

    return req.data;
};
  
export const evaluateChildGrowthRequest = async (id, payload) => {

    const req = await apiService.post(`/${path}/${id}/evaluate`, payload);

    if (req.status !== 200) {
        throw req.data.message || req.statusText || req
    }

    return req.data;
};
/* eslint-disable no-restricted-syntax */
import { all, call, put, takeLatest, takeLeading, } from 'redux-saga/effects';

import * as apicore from "services/core";
import * as type from './constant';
import * as action from './action';

function* getListItems({ params, selfRole }) {
  try {

    const data = [];
    let p = 0;
    let tp = 0;
    do {
      const { results, page, totalPages } = yield call(apicore.getVerificatorClinicRequest, { ...params, page: p + 1, limit: 50 }, selfRole);
      data.push(...results)
      p = page;
      tp = totalPages;
    } while (p < tp);
    
    yield put(action.getVerificatorClinicSuccess(data));

  } catch (error) {
    yield put(action.getVerificatorClinicError(error?.response?.data?.message || error?.response?.statusText || error.toString()));
  }
}

function* addItem({ payload, selfRole }) {
  try {

    const response = yield call(apicore.addVerificatorClinicRequest, payload, selfRole);
    yield put(action.addVerificatorClinicSuccess(response));

  } catch (error) {
    yield put(action.manipulationVerificatorClinicError(error?.response?.data?.message || error?.response?.statusText || error.toString()));
  }
}

function* updateItem({ id, payload, selfRole }) {
  try {

    const response = yield call(apicore.updateVerificatorClinicRequest, id, payload, selfRole);
    yield put(action.updateVerificatorClinicSuccess(response));

  } catch (error) {
    yield put(action.manipulationVerificatorClinicError(error?.response?.data?.message || error?.response?.statusText || error.toString()));
  }
}

function* deleteItem({ ids, selfRole }) {
  try {

    for (const id of ids) {
      yield call(apicore.deleteVerificatorClinicRequest, id, selfRole);
    }
    
    yield put(action.deleteVerificatorClinicSuccess(true));

  } catch (error) {
    yield put(action.manipulationVerificatorClinicError(error?.response?.data?.message || error?.response?.statusText || error.toString()));
  }
}

export function* watchGetList() {
  yield takeLatest(type.GET, getListItems);
}
export function* watchAddList() {
  yield takeLeading(type.ADD, addItem);
}
export function* watchUpdateItem() {
  yield takeLeading(type.UPDATE, updateItem);
}
export function* watchDeleteItem() {
  yield takeLeading(type.DELETE, deleteItem);
}

export default function* rootSaga() {
  yield all([
    watchGetList(),
    watchUpdateItem(),
    watchAddList(),
    watchDeleteItem(),
  ]);
}

import * as type from './constant';

const initialState = {
  dataClinic: [],

  updatedClinic: null,
  addedClinic: null,
  deletedClinic: null,

  loading: false,
  loadingManipulation: false,
  error: null,
  errorManipulation: null,
};
export default (state = initialState, action) => {

  switch (action.type) {

    case type.GET:
      return {
        ...state,
        updatedClinic: null,
        addedClinic: null,
        deletedClinic: null,
        loading: true,
        loadingManipulation: false,
        error: null,
        errorManipulation: null,
      };

    case type.GET_SUCCESS:
      return {
        ...state,
        dataClinic: [...action.payload],
        loading: false,
      };

    case type.GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case type.ADD:
      return {
        ...state,
        addedClinic: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.ADD_SUCCESS:
      return {
        ...state,
        addedClinic: action.payload,
        loadingManipulation: false,
      };

    case type.UPDATE:
      return {
        ...state,
        updatedClinic: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.UPDATE_SUCCESS:
      return {
        ...state,
        updatedClinic: action.payload,
        loadingManipulation: false,
      };

    case type.DELETE:
      return {
        ...state,
        deletedClinic: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.DELETE_SUCCESS:
      return {
        ...state,
        deletedClinic: action.payload,
        loadingManipulation: false,
      };

    case type.MANIPULATION_ERROR:
      return {
        ...state,
        loadingManipulation: false,
        errorManipulation: action.error,
      };

    default:
      return state;
  }
};

/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-else-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken, clearAuth, getCurrentUser } from 'helpers/Utils';
import { apiServiceBaseUrl, storageServiceBaseUrl, tataNaskahServiceUrl } from 'constants/integrations';

let isRefreshing = false;
let failedQueue = [];
const processQueue = (err, token = null) => {
    failedQueue.forEach(x => {
        if (err) {
            x.reject(err)
        }
        else {
            x.resolve(token)
        }
    })

    failedQueue = [];
}

const unauthenticated = () => {
  console.log('unauthenticated')
  clearAuth()
  createBrowserHistory().push('/user/login');
  window.location.reload();
}

export const refreshTokens = async () => {
  console.log('refresh tokens ...')

  isRefreshing = true;

  const refreshToken = getRefreshToken();

  if (!refreshToken) unauthenticated();

  try {

    const res = await axios.post(
      `${apiServiceBaseUrl}/auth/refresh-tokens`,
      {
        refreshToken,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  
    setRefreshToken(res.data.refresh.token)
    setAccessToken(res.data.access.token)

    processQueue(null, res.data.refresh.token)

    isRefreshing = false;

    return res.data.access.token;

  } catch (error) {
    if (error?.response?.status === 401) {
      processQueue(error)
      unauthenticated()
    }
    else{
      isRefreshing = false;
      return Promise.reject(error);
    }
  }
}

const handleUnauthorize = async (instance, error) => {
  console.log('handle unauthorized ...')

  const originalRequest = error.config;

  if (originalRequest.url.includes('/auth/login')) {
    throw error;
  }

  if (isRefreshing) {
    return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject })
    })
        .then(accessToken => {
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            return instance(originalRequest);
        })
        .catch(err => Promise.reject(err))
  }

  try {

    const accessToken = await refreshTokens()

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    return instance(originalRequest);
  }
  catch (err) {
    return Promise.reject(err);
  }
}

const axiosAPIService = axios.create({
  baseURL: apiServiceBaseUrl,
});

axiosAPIService.interceptors.request.use(
  async (config) => {
    const accessToken = getAccessToken();
    const currentUser = getCurrentUser();
    
    const selfRole = config.headers['x-self-role'] || (currentUser?.role === 'member' ? 'self' : undefined);
    
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
      'Accept': 'application/json',

      'Authorization': accessToken ? `Bearer ${accessToken}` : undefined,
      'x-self-role': selfRole,
    };

    Object.keys(config.headers).forEach(x => { 
      if (!x) {
        delete config.headers[x]
      }
    })

    return config;
  },
  (error) => Promise.reject(error)
);

axiosAPIService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {

    if (error.response?.status === 401) {
      const solution = handleUnauthorize(axiosAPIService, error);
      return solution;
    }
    else {
      return Promise.reject(error);
    }

  }
);

export const apiService = axiosAPIService;
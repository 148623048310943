import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
} from './constant';

export const loginUser = (username, password) => ({
  type: LOGIN_USER,
  username,
  password
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (payload) => ({
  type: LOGIN_USER_ERROR,
  payload,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

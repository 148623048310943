import * as type from './constant';

export const getChildHealthcare = (params = undefined, selfRole = undefined) => ({
  type: type.GET,
  params,
  selfRole,
});

export const getChildHealthcareSuccess = (payload) => ({
  type: type.GET_SUCCESS,
  payload,
});

export const getChildHealthcareError = (error) => ({
  type: type.GET_ERROR,
  error,
});

export const addChildHealthcare = (payload, selfRole = undefined) => ({
  type: type.ADD,
  payload,
  selfRole
});

export const addChildHealthcareSuccess = (payload) => ({
  type: type.ADD_SUCCESS,
  payload,
});

export const updateChildHealthcare = (id, payload, selfRole = undefined) => ({
  type: type.UPDATE,
  id,
  payload,
  selfRole,
});

export const updateChildHealthcareSuccess = (payload) => ({
  type: type.UPDATE_SUCCESS,
  payload,
});

export const deleteChildHealthcare = (ids, selfRole = undefined) => ({
  type: type.DELETE,
  ids,
  selfRole,
});

export const deleteChildHealthcareSuccess = (payload) => ({
  type: type.DELETE_SUCCESS,
  payload,
});

export const manipulationChildHealthcareError = (error) => ({
  type: type.MANIPULATION_ERROR,
  error,
});

import * as type from './constant';

const initialState = {
  dataChild: [],

  updatedChild: null,
  addedChild: null,
  deletedChild: null,

  programAdded: null,
  programRemoved: null,

  evaluatedChildGrowth: null,

  loading: false,
  loadingManipulation: false,
  error: null,
  errorManipulation: null,
};
export default (state = initialState, action) => {
  let newData;
  let childIndex;

  switch (action.type) {

    case type.GET:
      return {
        ...state,
        updatedChild: null,
        addedChild: null,
        deletedChild: null,
        loading: true,
        loadingManipulation: false,
        error: null,
        errorManipulation: null,
      };

    case type.GET_SUCCESS:
      return {
        ...state,
        dataChild: [...action.payload],
        loading: false,
      };

    case type.GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case type.ADD:
      return {
        ...state,
        addedChild: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.ADD_SUCCESS:
      return {
        ...state,
        addedChild: action.payload,
        loadingManipulation: false,
      };

    case type.UPDATE:
      return {
        ...state,
        updatedChild: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.UPDATE_SUCCESS:
      return {
        ...state,
        updatedChild: action.payload,
        loadingManipulation: false,
      };

    case type.DELETE:
      return {
        ...state,
        deletedChild: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.DELETE_SUCCESS:
      return {
        ...state,
        deletedChild: action.payload,
        loadingManipulation: false,
      };

    case type.PROGRAM_ADD:
      return {
        ...state,
        programAdded: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.PROGRAM_ADD_SUCCESS:
      newData = state.dataChild;
      childIndex = newData.findIndex((x) => x.id === action.payload.id);
      newData[childIndex] = action.payload;

      return {
        ...state,
        dataChild: [...newData],
        programAdded: action.payload,
        loadingManipulation: false,
      };

    case type.GROWTH_EVALUATE:
      return {
        ...state,
        evaluatedChildGrowth: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.GROWTH_EVALUATE_SUCCESS:
      newData = state.dataChild;
      childIndex = newData.findIndex((x) => x.id === action.payload.child);
      const growthIndex = newData[childIndex].nutrition_history.findIndex((x) => x.id === action.payload.id);
      newData[childIndex].nutrition_history[growthIndex] = action.payload;

      return {
        ...state,
        dataChild: [...newData],
        evaluatedChildGrowth: action.payload,
        loadingManipulation: false,
      };

    case type.MANIPULATION_ERROR:
      return {
        ...state,
        loadingManipulation: false,
        errorManipulation: action.error,
      };

    default:
      return state;
  }
};

const name = 'CHILD'

export const GET = `${name}_GET`;
export const GET_SUCCESS = `${name}_GET_SUCCESS`;
export const GET_ERROR = `${name}_GET_ERROR`;
export const ADD = `${name}_ADD`;
export const ADD_SUCCESS = `${name}_ADD_SUCCESS`;
export const UPDATE = `${name}_UPDATE`;
export const UPDATE_SUCCESS = `${name}_UPDATE_SUCCESS`;
export const DELETE = `${name}_DELETE`;
export const DELETE_SUCCESS = `${name}_DELETE_SUCCESS`;

export const PROGRAM_ADD = `${name}_PROGRAM_ADD`;
export const PROGRAM_ADD_SUCCESS = `${name}_PROGRAM_ADD_SUCCESS`;

export const PROGRAM_REMOVE = `${name}_PROGRAM_REMOVE`;
export const PROGRAM_REMOVE_SUCCESS = `${name}_PROGRAM_REMOVE_SUCCESS`;

export const GROWTH_EVALUATE = `${name}_GROWTH_EVALUATE`;
export const GROWTH_EVALUATE_SUCCESS = `${name}_GROWTH_EVALUATE_SUCCESS`;

export const MANIPULATION_ERROR = `${name}_MANIPULATION_ERROR`;

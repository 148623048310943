import { all } from 'redux-saga/effects';

import authSagas from './auth/saga';
import childHealthcareSagas from './childhealthcare/saga';
import childSagas from './child/saga';
import clinicSagas from './clinic/saga';
import operatorSagas from './operator/saga';
import parentSagas from './parent/saga';
import programPackageSagas from './programpackage/saga';
import programPackageCategorySagas from './programpackagecategory/saga';
import regionSagas from './region/saga';
import reportSagas from './report/saga';
import verificatorClinicSagas from './verificatorclinic/saga';
import verificatorKabupatenSagas from './verificatorkabupaten/saga';

import exampleSagas from './example-basic/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    childSagas(),
    childHealthcareSagas(),
    clinicSagas(),
    operatorSagas(),
    parentSagas(),
    programPackageSagas(),
    programPackageCategorySagas(),
    regionSagas(),
    reportSagas(),
    verificatorClinicSagas(),
    verificatorKabupatenSagas(),

    exampleSagas(),
  ]);
}

import * as type from './constant';

export const getVerificatorClinic = (params = undefined, selfRole = undefined) => ({
  type: type.GET,
  params,
  selfRole,
});

export const getVerificatorClinicSuccess = (payload) => ({
  type: type.GET_SUCCESS,
  payload,
});

export const getVerificatorClinicError = (error) => ({
  type: type.GET_ERROR,
  error,
});

export const addVerificatorClinic = (payload, selfRole = undefined) => ({
  type: type.ADD,
  payload,
  selfRole
});

export const addVerificatorClinicSuccess = (payload) => ({
  type: type.ADD_SUCCESS,
  payload,
});

export const updateVerificatorClinic = (id, payload, selfRole = undefined) => ({
  type: type.UPDATE,
  id,
  payload,
  selfRole,
});

export const updateVerificatorClinicSuccess = (payload) => ({
  type: type.UPDATE_SUCCESS,
  payload,
});

export const deleteVerificatorClinic = (ids, selfRole = undefined) => ({
  type: type.DELETE,
  ids,
  selfRole,
});

export const deleteVerificatorClinicSuccess = (payload) => ({
  type: type.DELETE_SUCCESS,
  payload,
});

export const manipulationVerificatorClinicError = (error) => ({
  type: type.MANIPULATION_ERROR,
  error,
});

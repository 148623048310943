import { all, call, fork, put, takeLatest, takeEvery } from 'redux-saga/effects';

import { clearAuth } from 'helpers/Utils';
import { createBrowserHistory } from 'history';

import * as apicore from "services/core";
import * as type from './constant';
import * as action from './action';

function* login({ username, password }) {
  try {
    
    const { user } = yield call(apicore.login, username, password);
    yield put(action.loginUserSuccess(user));

  } catch (error) {
    yield put(action.loginUserError(error?.response?.data?.message || error?.response?.statusText || error.toString()));
  }
}

function* logout() {
  try {
    yield call(apicore.logout);
  } catch (error) {
    console.log(error)
  }

  clearAuth()
  createBrowserHistory().push('/user/login');
  window.location.reload();
}

export function* watchLoginUser() {
  yield takeLatest(type.LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(type.LOGOUT_USER, logout);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
  ]);
}

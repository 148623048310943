/* eslint-disable no-restricted-syntax */
import { all, call, put, takeLatest, takeLeading, } from 'redux-saga/effects';

import * as apicore from "services/core";
import * as type from './constant';
import * as action from './action';

function* getListItems({ params, selfRole }) {
  try {

    const data = [];
    let p = 0;
    let tp = 0;
    do {
      const { results, page, totalPages } = yield call(apicore.getChildRequest, { ...params, page: p + 1, limit: 50 }, selfRole);
      data.push(...results)
      p = page;
      tp = totalPages;
    } while (p < tp);
    
    yield put(action.getChildSuccess(data));

  } catch (error) {
    yield put(action.getChildError(error?.response?.data?.message || error?.response?.statusText || error.toString()));
  }
}

function* addItem({ payload, selfRole }) {
  try {

    const response = yield call(apicore.addChildRequest, payload, selfRole);
    yield put(action.addChildSuccess(response));

  } catch (error) {
    yield put(action.manipulationChildError(error?.response?.data?.message || error?.response?.statusText || error.toString()));
  }
}

function* updateItem({ id, payload, selfRole }) {
  try {

    const response = yield call(apicore.updateChildRequest, id, payload, selfRole);
    yield put(action.updateChildSuccess(response));

  } catch (error) {
    yield put(action.manipulationChildError(error?.response?.data?.message || error?.response?.statusText || error.toString()));
  }
}

function* deleteItem({ ids, selfRole }) {
  try {

    for (const id of ids) {
      yield call(apicore.deleteChildRequest, id, selfRole);
    }
    
    yield put(action.deleteChildSuccess(true));

  } catch (error) {
    yield put(action.manipulationChildError(error?.response?.data?.message || error?.response?.statusText || error.toString()));
  }
}

function* addProgram({ id, payload }) {
  try {

    const response = yield call(apicore.addChildProgram, id, payload);
    yield put(action.childAddProgramSuccess(response));

  } catch (error) {
    yield put(action.manipulationChildError(error?.response?.data?.message || error?.response?.statusText || error.toString()));
  }
}

function* evaluateItemGrowth({ id, payload }) {
  try {

    const response = yield call(apicore.evaluateChildGrowthRequest, id, payload);
    yield put(action.evaluateChildGrowthSuccess(response));

  } catch (error) {
    yield put(action.manipulationChildError(error?.response?.data?.message || error?.response?.statusText || error.toString()));
  }
}

export function* watchGetList() {
  yield takeLatest(type.GET, getListItems);
}
export function* watchAddList() {
  yield takeLeading(type.ADD, addItem);
}
export function* watchUpdateItem() {
  yield takeLeading(type.UPDATE, updateItem);
}
export function* watchDeleteItem() {
  yield takeLeading(type.DELETE, deleteItem);
}
export function* watchAddProgram() {
  yield takeLeading(type.PROGRAM_ADD, addProgram);
}
export function* watchEvaluateItemGrowth() {
  yield takeLeading(type.GROWTH_EVALUATE, evaluateItemGrowth);
}

export default function* rootSaga() {
  yield all([
    watchGetList(),
    watchUpdateItem(),
    watchAddList(),
    watchDeleteItem(),
    watchAddProgram(),
    watchEvaluateItemGrowth(),
  ]);
}

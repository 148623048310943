import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';

import authReducer from './auth/reducer';
import childReducer from './child/reducer';
import childHealthcareReducer from './childhealthcare/reducer';
import clinicReducer from './clinic/reducer';
import operatorReducer from './operator/reducer';
import parentReducer from './parent/reducer';
import programPackageReducer from './programpackage/reducer';
import programPackageCategoryReducer from './programpackagecategory/reducer';
import regionReducer from './region/reducer';
import reportReducer from './report/reducer';
import verificatorClinicReducer from './verificatorclinic/reducer';
import verificatorKabupatenReducer from './verificatorkabupaten/reducer';

import exampleReducer from './example-basic/reducer';

const reducers = combineReducers({
  menu,
  settings,

  authReducer,
  childReducer,
  childHealthcareReducer,
  clinicReducer,
  operatorReducer,
  parentReducer,
  programPackageReducer,
  programPackageCategoryReducer,
  regionReducer,
  reportReducer,
  verificatorClinicReducer,
  verificatorKabupatenReducer,

  exampleReducer,
});

export default reducers;

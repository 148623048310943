import { apiService } from 'services/axiosHandler';

const path = 'reports';

export const getReportChildHealthStatusRequest = async (params = undefined) => {
    
    const req = await apiService.get(`/${path}/childs-health-status`, { 
        params,
    });

    if (req.status !== 200) {
        throw req.data.message || req.statusText || req
    }

    return req.data;
};

export const getReportChildCoordinateRequest = async (params = undefined) => {
    
    const req = await apiService.get(`/${path}/childs-coordinate`, { 
        params,
    });

    if (req.status !== 200) {
        throw req.data.message || req.statusText || req
    }

    return req.data;
};

export const getReportRecapPerClinicRequest = async (params = undefined) => {
    
    const req = await apiService.get(`/${path}/child-growth-perclinic`, { 
        params,
    });

    if (req.status !== 200) {
        throw req.data.message || req.statusText || req
    }

    return req.data;
};
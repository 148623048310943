import moment from 'moment-timezone'
import * as typeChildHealthStatus from './constant_childhealthstatus';
import * as typeChildCoordinate from './constant_childcoordinate';
import * as typeRecapPerClinic from './constant_recapperclinic';

const currentYear = moment.tz('Asia/Makassar').format('YYYY');
const currentMonth = moment.tz('Asia/Makassar').format('YYYY-MM');

const initialState = {
  dataReportChildHealthStatus: [],
  yearReportChildHealthStatus: currentYear,
  loadingReportChildHealthStatus: false,
  errorReportChildHealthStatus: null,

  dataReportChildCoordinate: [],
  loadingReportChildCoordinate: false,
  errorReportChildCoordinate: null,

  dataReportRecapPerClinic: [],
  monthReportRecapPerClinic: currentMonth,
  loadingReportRecapPerClinic: false,
  errorReportRecapPerClinic: null,

  yearOptions: [
    {
      value: currentYear,
      label: 'Tahun Ini'
    },
    {
      value: currentYear - 1,
      label: 'Tahun Lalu'
    },
    {
      value: currentYear - 2,
      label: '2 Tahun Lalu'
    },
  ],
};
export default (state = initialState, action) => {

  switch (action.type) {

    case typeChildHealthStatus.GET:
      return {
        ...state,
        [`loadingReportChildHealthStatus${action.category}`]: true,
        [`errorReportChildHealthStatus${action.category}`]: null,
      };

    case typeChildHealthStatus.GET_SUCCESS:
      return {
        ...state,
        [`dataReportChildHealthStatus${action.category}${action.year}`]: [...action.payload],
        [`yearReportChildHealthStatus${action.category}`]: action.year,
        [`loadingReportChildHealthStatus${action.category}`]: false,
      };

    case typeChildHealthStatus.GET_ERROR:
      return {
        ...state,
        [`loadingReportChildHealthStatus${action.category}`]: false,
        [`errorReportChildHealthStatus${action.category}`]: action.error,
      };

    case typeChildCoordinate.GET:
      return {
        ...state,
        [`loadingReportChildCoordinate${action.category}`]: true,
        [`errorReportChildCoordinate${action.category}`]: null,
      };

    case typeChildCoordinate.GET_SUCCESS:
      return {
        ...state,
        [`dataReportChildCoordinate${action.category}${action.value}`]: [...action.payload],
        [`valueReportChildCoordinate${action.category}`]: action.value,
        [`loadingReportChildCoordinate${action.category}`]: false,
      };

    case typeChildCoordinate.GET_ERROR:
      return {
        ...state,
        [`loadingReportChildCoordinate${action.category}`]: false,
        [`errorReportChildCoordinate${action.category}`]: action.error,
      };

    case typeRecapPerClinic.GET:
      return {
        ...state,
        [`loadingReportRecapPerClinic`]: true,
        [`errorReportRecapPerClinic`]: null,
      };

    case typeRecapPerClinic.GET_SUCCESS:
      return {
        ...state,
        [`dataReportRecapPerClinic${action.month}`]: [...action.payload],
        [`monthReportRecapPerClinic`]: action.month,
        [`loadingReportRecapPerClinic`]: false,
      };

    case typeRecapPerClinic.GET_ERROR:
      return {
        ...state,
        [`loadingReportRecapPerClinic`]: false,
        [`errorReportRecapPerClinic`]: action.error,
      };

    default:
      return state;
  }
};

import * as type from './constant';

export const getChild = (params = undefined, selfRole = undefined) => ({
  type: type.GET,
  params,
  selfRole,
});

export const getChildSuccess = (payload) => ({
  type: type.GET_SUCCESS,
  payload,
});

export const getChildError = (error) => ({
  type: type.GET_ERROR,
  error,
});

export const addChild = (payload, selfRole = undefined) => ({
  type: type.ADD,
  payload,
  selfRole
});

export const addChildSuccess = (payload) => ({
  type: type.ADD_SUCCESS,
  payload,
});

export const updateChild = (id, payload, selfRole = undefined) => ({
  type: type.UPDATE,
  id,
  payload,
  selfRole,
});

export const updateChildSuccess = (payload) => ({
  type: type.UPDATE_SUCCESS,
  payload,
});

export const deleteChild = (ids, selfRole = undefined) => ({
  type: type.DELETE,
  ids,
  selfRole,
});

export const deleteChildSuccess = (payload) => ({
  type: type.DELETE_SUCCESS,
  payload,
});

export const childAddProgram = (id, payload) => ({
  type: type.PROGRAM_ADD,
  id,
  payload,
});

export const childAddProgramSuccess = (payload) => ({
  type: type.PROGRAM_ADD_SUCCESS,
  payload,
});

export const childRemoveProgram = (id, payload) => ({
  type: type.PROGRAM_REMOVE,
  id,
  payload,
});

export const childRemoveProgramSuccess = (payload) => ({
  type: type.PROGRAM_REMOVE_SUCCESS,
  payload,
});

export const evaluateChildGrowth = (id, payload) => ({
  type: type.GROWTH_EVALUATE,
  id,
  payload,
});

export const evaluateChildGrowthSuccess = (payload) => ({
  type: type.GROWTH_EVALUATE_SUCCESS,
  payload,
});

export const manipulationChildError = (error) => ({
  type: type.MANIPULATION_ERROR,
  error,
});

import * as type from './constant';

export const getClinic = (params = undefined, selfRole = undefined) => ({
  type: type.GET,
  params,
  selfRole,
});

export const getClinicSuccess = (payload) => ({
  type: type.GET_SUCCESS,
  payload,
});

export const getClinicError = (error) => ({
  type: type.GET_ERROR,
  error,
});

export const addClinic = (payload, selfRole = undefined) => ({
  type: type.ADD,
  payload,
  selfRole
});

export const addClinicSuccess = (payload) => ({
  type: type.ADD_SUCCESS,
  payload,
});

export const updateClinic = (id, payload, selfRole = undefined) => ({
  type: type.UPDATE,
  id,
  payload,
  selfRole,
});

export const updateClinicSuccess = (payload) => ({
  type: type.UPDATE_SUCCESS,
  payload,
});

export const deleteClinic = (ids, selfRole = undefined) => ({
  type: type.DELETE,
  ids,
  selfRole,
});

export const deleteClinicSuccess = (payload) => ({
  type: type.DELETE_SUCCESS,
  payload,
});

export const manipulationClinicError = (error) => ({
  type: type.MANIPULATION_ERROR,
  error,
});

import * as type from './constant';

export const getProgramPackage = (params = undefined, selfRole = undefined) => ({
  type: type.GET,
  params,
  selfRole,
});

export const getProgramPackageSuccess = (payload) => ({
  type: type.GET_SUCCESS,
  payload,
});

export const getProgramPackageError = (error) => ({
  type: type.GET_ERROR,
  error,
});

export const addProgramPackage = (payload, selfRole = undefined) => ({
  type: type.ADD,
  payload,
  selfRole
});

export const addProgramPackageSuccess = (payload) => ({
  type: type.ADD_SUCCESS,
  payload,
});

export const updateProgramPackage = (id, payload, selfRole = undefined) => ({
  type: type.UPDATE,
  id,
  payload,
  selfRole,
});

export const updateProgramPackageSuccess = (payload) => ({
  type: type.UPDATE_SUCCESS,
  payload,
});

export const deleteProgramPackage = (ids, selfRole = undefined) => ({
  type: type.DELETE,
  ids,
  selfRole,
});

export const deleteProgramPackageSuccess = (payload) => ({
  type: type.DELETE_SUCCESS,
  payload,
});

export const manipulationProgramPackageError = (error) => ({
  type: type.MANIPULATION_ERROR,
  error,
});

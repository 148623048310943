import * as type from './constant';

export const getModuleName = (params = undefined, selfRole = undefined) => ({
  type: type.GET,
  params,
  selfRole,
});

export const getModuleNameSuccess = (payload) => ({
  type: type.GET_SUCCESS,
  payload,
});

export const getModuleNameError = (error) => ({
  type: type.GET_ERROR,
  error,
});

export const addModuleName = (payload, selfRole = undefined) => ({
  type: type.ADD,
  payload,
  selfRole
});

export const addModuleNameSuccess = (payload) => ({
  type: type.ADD_SUCCESS,
  payload,
});

export const updateModuleName = (id, payload, selfRole = undefined) => ({
  type: type.UPDATE,
  id,
  payload,
  selfRole,
});

export const updateModuleNameSuccess = (payload) => ({
  type: type.UPDATE_SUCCESS,
  payload,
});

export const deleteModuleName = (ids, selfRole = undefined) => ({
  type: type.DELETE,
  ids,
  selfRole,
});

export const deleteModuleNameSuccess = (payload) => ({
  type: type.DELETE_SUCCESS,
  payload,
});

export const manipulationModuleNameError = (error) => ({
  type: type.MANIPULATION_ERROR,
  error,
});

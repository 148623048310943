import * as type from './constant';

const initialState = {
  dataProgramPackage: [],

  updatedProgramPackage: null,
  addedProgramPackage: null,
  deletedProgramPackage: null,

  loading: false,
  loadingManipulation: false,
  error: null,
  errorManipulation: null,
};
export default (state = initialState, action) => {

  switch (action.type) {

    case type.GET:
      return {
        ...state,
        updatedProgramPackage: null,
        addedProgramPackage: null,
        deletedProgramPackage: null,
        loading: true,
        loadingManipulation: false,
        error: null,
        errorManipulation: null,
      };

    case type.GET_SUCCESS:
      return {
        ...state,
        dataProgramPackage: [...action.payload],
        loading: false,
      };

    case type.GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case type.ADD:
      return {
        ...state,
        addedProgramPackage: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.ADD_SUCCESS:
      return {
        ...state,
        addedProgramPackage: action.payload,
        loadingManipulation: false,
      };

    case type.UPDATE:
      return {
        ...state,
        updatedProgramPackage: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.UPDATE_SUCCESS:
      return {
        ...state,
        updatedProgramPackage: action.payload,
        loadingManipulation: false,
      };

    case type.DELETE:
      return {
        ...state,
        deletedProgramPackage: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.DELETE_SUCCESS:
      return {
        ...state,
        deletedProgramPackage: action.payload,
        loadingManipulation: false,
      };

    case type.MANIPULATION_ERROR:
      return {
        ...state,
        loadingManipulation: false,
        errorManipulation: action.error,
      };

    default:
      return state;
  }
};

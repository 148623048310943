import * as type from './constant';

const initialState = {
  dataOperator: [],

  updatedOperator: null,
  addedOperator: null,
  deletedOperator: null,

  loading: false,
  loadingManipulation: false,
  error: null,
  errorManipulation: null,
};
export default (state = initialState, action) => {

  switch (action.type) {

    case type.GET:
      return {
        ...state,
        updatedOperator: null,
        addedOperator: null,
        deletedOperator: null,
        loading: true,
        loadingManipulation: false,
        error: null,
        errorManipulation: null,
      };

    case type.GET_SUCCESS:
      return {
        ...state,
        dataOperator: [...action.payload],
        loading: false,
      };

    case type.GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case type.ADD:
      return {
        ...state,
        addedOperator: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.ADD_SUCCESS:
      return {
        ...state,
        addedOperator: action.payload,
        loadingManipulation: false,
      };

    case type.UPDATE:
      return {
        ...state,
        updatedOperator: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.UPDATE_SUCCESS:
      return {
        ...state,
        updatedOperator: action.payload,
        loadingManipulation: false,
      };

    case type.DELETE:
      return {
        ...state,
        deletedOperator: null,
        loadingManipulation: true,
        errorManipulation: null,
      };

    case type.DELETE_SUCCESS:
      return {
        ...state,
        deletedOperator: action.payload,
        loadingManipulation: false,
      };

    case type.MANIPULATION_ERROR:
      return {
        ...state,
        loadingManipulation: false,
        errorManipulation: action.error,
      };

    default:
      return state;
  }
};

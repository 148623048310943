import * as type from './constant';

export const getRegion = (params = undefined, selfRole = undefined) => ({
  type: type.GET,
  params,
  selfRole,
});

export const getRegionSuccess = (payload) => ({
  type: type.GET_SUCCESS,
  payload,
});

export const getRegionError = (error) => ({
  type: type.GET_ERROR,
  error,
});

export const addRegion = (payload, selfRole = undefined) => ({
  type: type.ADD,
  payload,
  selfRole
});

export const addRegionSuccess = (payload) => ({
  type: type.ADD_SUCCESS,
  payload,
});

export const updateRegion = (id, payload, selfRole = undefined) => ({
  type: type.UPDATE,
  id,
  payload,
  selfRole,
});

export const updateRegionSuccess = (payload) => ({
  type: type.UPDATE_SUCCESS,
  payload,
});

export const deleteRegion = (ids, selfRole = undefined) => ({
  type: type.DELETE,
  ids,
  selfRole,
});

export const deleteRegionSuccess = (payload) => ({
  type: type.DELETE_SUCCESS,
  payload,
});

export const manipulationRegionError = (error) => ({
  type: type.MANIPULATION_ERROR,
  error,
});

import * as type from './constant';

export const getParent = (params = undefined, selfRole = undefined) => ({
  type: type.GET,
  params,
  selfRole,
});

export const getParentSuccess = (payload) => ({
  type: type.GET_SUCCESS,
  payload,
});

export const getParentError = (error) => ({
  type: type.GET_ERROR,
  error,
});

export const addParent = (payload, selfRole = undefined) => ({
  type: type.ADD,
  payload,
  selfRole
});

export const addParentSuccess = (payload) => ({
  type: type.ADD_SUCCESS,
  payload,
});

export const updateParent = (id, payload, selfRole = undefined) => ({
  type: type.UPDATE,
  id,
  payload,
  selfRole,
});

export const updateParentSuccess = (payload) => ({
  type: type.UPDATE_SUCCESS,
  payload,
});

export const deleteParent = (ids, selfRole = undefined) => ({
  type: type.DELETE,
  ids,
  selfRole,
});

export const deleteParentSuccess = (payload) => ({
  type: type.DELETE_SUCCESS,
  payload,
});

export const manipulationParentError = (error) => ({
  type: type.MANIPULATION_ERROR,
  error,
});

/* eslint-disable no-restricted-syntax */
import { all, call, put, takeEvery, takeLatest, takeLeading, } from 'redux-saga/effects';

import * as apicore from "services/core";
import * as typeChildHealthStatus from './constant_childhealthstatus';
import * as typeChildCoordinate from './constant_childcoordinate';
import * as typeRecapPerClinic from './constant_recapperclinic';
import * as action from './action';

function* getReportChildHealthStatus({ category, year }) {
  try {
    
    const data = yield call(apicore.getReportChildHealthStatusRequest, { category, year });
    yield put(action.getReportChildHealthStatusSuccess(data, category, year));

  } catch (error) {
    yield put(action.getReportChildHealthStatusError(error?.response?.data?.message || error?.response?.statusText || error.toString(), category));
  }
}

function* getReportChildCoordinate({ category='', value }) {
  try {

    const filterQuery = !!category && !!value ? { category, value } : {};

    const data = [];
    let p = 0;
    let tp = 0;
    
    do {
      const { results, page, totalPages } = yield call(apicore.getReportChildCoordinateRequest, { page: p + 1, limit: 50, ...filterQuery });
      data.push(...results)
      p = page;
      tp = totalPages;
    } while (p < tp);
    
    yield put(action.getReportChildCoordinateSuccess(data, category, value));

  } catch (error) {
    yield put(action.getReportChildCoordinateError(error?.response?.data?.message || error?.response?.statusText || error.toString(), category));
  }
}

function* getReportRecapPerClinic({ params }) {
  try {
    
    const data = yield call(apicore.getReportRecapPerClinicRequest, { ...params });
    yield put(action.getReportRecapPerClinicSuccess(data, `${params.year}-${params.month}`));

  } catch (error) {
    yield put(action.getReportRecapPerClinicError(error?.response?.data?.message || error?.response?.statusText || error.toString(), category));
  }
}

export function* watchGetReportChildHealthStatus() {
  yield takeEvery(typeChildHealthStatus.GET, getReportChildHealthStatus);
}

export function* watchGetReportChildCoordinate() {
  yield takeLatest(typeChildCoordinate.GET, getReportChildCoordinate);
}

export function* watchGetReportRecapPerClinic() {
  yield takeLatest(typeRecapPerClinic.GET, getReportRecapPerClinic);
}

export default function* rootSaga() {
  yield all([
    watchGetReportChildHealthStatus(),
    watchGetReportChildCoordinate(),
    watchGetReportRecapPerClinic(),
  ]);
}

import * as type from './constant';

export const getProgramPackageCategory = (params = undefined, selfRole = undefined) => ({
  type: type.GET,
  params,
  selfRole,
});

export const getProgramPackageCategorySuccess = (payload) => ({
  type: type.GET_SUCCESS,
  payload,
});

export const getProgramPackageCategoryError = (error) => ({
  type: type.GET_ERROR,
  error,
});

export const addProgramPackageCategory = (payload, selfRole = undefined) => ({
  type: type.ADD,
  payload,
  selfRole
});

export const addProgramPackageCategorySuccess = (payload) => ({
  type: type.ADD_SUCCESS,
  payload,
});

export const updateProgramPackageCategory = (id, payload, selfRole = undefined) => ({
  type: type.UPDATE,
  id,
  payload,
  selfRole,
});

export const updateProgramPackageCategorySuccess = (payload) => ({
  type: type.UPDATE_SUCCESS,
  payload,
});

export const deleteProgramPackageCategory = (ids, selfRole = undefined) => ({
  type: type.DELETE,
  ids,
  selfRole,
});

export const deleteProgramPackageCategorySuccess = (payload) => ({
  type: type.DELETE_SUCCESS,
  payload,
});

export const manipulationProgramPackageCategoryError = (error) => ({
  type: type.MANIPULATION_ERROR,
  error,
});
